import { HttpResponse, post } from '@/api/Api';
import { THGCarUpdateStatusPayload } from '@/interfaces/thg/THGCarUpdateStatusPayload';
import { TokenType } from '@/enums/TokenType';
import { THGInfoMailPayload } from '@/interfaces/thg/THGInfoMailPayload';
import {
    THGCarEnergyUseNotMeasurablePayload,
    THGCarOfferInstantPayoutActionResponse,
    THGCarOfferOrRemoveInstantPayoutPayload,
    THGCarOverrideCO2EquivalentPayload,
    THGCarOverrideInstantPayoutAmountPayload,
    THGCarOverrideStatusPayload,
    THGCarPayoutActionResponse,
    THGCarPayoutPayload,
} from '@/interfaces/thg/THGCarActions';
import { processActionPayload } from '@/utils/thg';
import { api_url } from '@/api/thg/api';
import { THGActionResponse } from '@/interfaces/thg/THGActionResponse';

const CAR_ACTION_PATH = '/crm/car-actions';

/**
 * Trigger status update action.
 *
 * @param payload
 * @return response
 */
export async function triggerStatusUpdate(
    payload: THGCarUpdateStatusPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/update-status`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger info mail sending action.
 *
 * @param payload
 * @return response
 */
export async function triggerInfoMailSending(
    payload: THGInfoMailPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/send-info-mail`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger the PAYOUT action.
 *
 * @param payload
 * @return payload
 */
export async function triggerPayout(
    payload: THGCarPayoutPayload,
): Promise<HttpResponse<THGCarPayoutActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGCarPayoutActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/payout`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger an OVERRIDE-INSTANT-PAYOUT-AMOUNT action.
 *
 * @param payload
 * @return payload
 */
export async function triggerOverrideInstantPayoutAmount(
    payload: THGCarOverrideInstantPayoutAmountPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/override-instant-payout-amount`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger an ENERGY_USE_NOT_MEASURABLE action.
 *
 * @param payload
 * @return payload
 */
export async function triggerEneryUseNotMeasurable(
    payload: THGCarEnergyUseNotMeasurablePayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/set-energy-use-not-measurable`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger an OVERRIDE-CO2-EQUIVALENT action.
 *
 * @param payload
 * @return payload
 */
export async function triggerOverrideCO2Equivalent(
    payload: THGCarOverrideCO2EquivalentPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/override-co2-equivalent`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger an OVERRIDE-STATUS action.
 *
 * @param payload
 * @return payload
 */
export async function triggerOverrideStatus(
    payload: THGCarOverrideStatusPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/override-status`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger the REMOVE-OFFER-INSTANT-PAYOUT action.
 *
 * @param payload
 * @return payload
 */
export async function triggerRemoveOfferInstantPayout(
    payload: THGCarOfferOrRemoveInstantPayoutPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/remove-offer-instant-payout`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Trigger the OFFER-INSTANT-PAYOUT action.
 *
 * @param payload
 * @return payload
 */
export async function triggerOfferInstantPayout(
    payload: THGCarOfferOrRemoveInstantPayoutPayload,
): Promise<HttpResponse<THGCarOfferInstantPayoutActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGCarOfferInstantPayoutActionResponse>(
        `${api_url()}${CAR_ACTION_PATH}/offer-instant-payout`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}
