export enum THGCarTableColumn {
    DRIVE_CODE = 'drive_code',
    LICENSE_PLATE = 'license_plate',
    TERMS_VERSION = 'terms_version',
    INITIAL_REGISTRATION_DATE = 'initial_registration_date',
    DEREGISTRATION_DATE = 'deregistration_date',
    REGISTRATION_DATE = 'registration_date',
    UBA_SUBMITTED_DATE = 'uba_submitted_date',
    FIRST_NAME = 'first_name',
    NAME = 'name',
    PROBABILITIES = 'probabilities',
    PAYOFF_MODEL = 'payoff_model',
    OFFER_INSTANT_PAYOUT = 'offer_instant_payout',
    INSTANT_PAYOUT_AMOUNT = 'instant_payout_amount',
    CURRENT_INSTANT_PAYOUT_AMOUNT = 'current_instant_payout_amount',
}
