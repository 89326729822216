import { get, HttpResponse } from '@/api/Api';
import { THGChangeHistoryItem } from '@/interfaces/thg/THGChangeHistoryItem';
import { api_url } from '@/api/thg/api';
import { TokenType } from '@/enums/TokenType';
import { parseISO } from 'date-fns';

export async function fetchTHGChangeHistoryItems(
    doc_id: string,
): Promise<HttpResponse<THGChangeHistoryItem[]>> {
    const res = await get<THGChangeHistoryItem[]>(
        `${api_url()}/crm/change-history/${doc_id}`,
        TokenType.THG_API_KEY,
    );

    res.parsedBody?.map((item) => {
        item.created_at = parseISO(item.created_at as unknown as string);

        return item;
    });

    return res;
}
